import ArrowLink from './arrowLink.tsx'
import { type Link } from '#types/graphql.page.ts'

export default function CallToActionCard({
	title,
	description,
	link,
}: {
	title: string
	description: string
	link: Link
}) {
	return (
		<div
			className="relative z-30 flex h-[400px] w-full flex-col place-content-center bg-green-100"
			style={{
				backgroundImage: `url(/assets/background.svg)`,
			}}
		>
			<div className="container flex flex-col space-y-[30px]">
				<h2 className="font-serif text-3xl font-medium leading-8 text-white">
					{title}
				</h2>
				<hr className="h-0.5 w-[378px] border-0 bg-copper-100" />
				<div
					className="call-to-action"
					dangerouslySetInnerHTML={{ __html: description }}
				></div>
				{link?.url && (
					<ArrowLink
						url={link.url}
						type={link.type}
						text={link.text}
						className="text-copper-300"
					/>
				)}
			</div>
		</div>
	)
}
